import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import DashboardPage from 'screens/dashboard/DashboardPage';
import ProfilePage from 'screens/profile/ProfilePage';
import ChangePasswordPage from 'screens/profile/ChangePasswordPage';
import UsersPage from 'screens/users/UsersPage';
import UserRegisterPage from 'screens/users/UserRegisterPage';
import UserPermissionsPage from 'screens/users/UserPermissionsPage';
import HelpPage from 'screens/help/HelpPage';
import TablePage from 'screens/table/TablePage';
import TableAprove from 'screens/table/TableP';
import AprovePage from 'screens/table/AprovePage';
import AuthPage from 'screens/auth/AuthPage';
import PrivateRoute from './Authorize';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: DashboardPage,
        exact: true,
        private: true,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardPage,
        exact: true,
        private: true,
    },
    {
        path: '/login',
        name: 'Login',
        component: AuthPage,
        exact: true,
        private: false,
    },
    {
        path: '/ajuda',
        name: 'Help',
        component: HelpPage,
        exact: true,
        private: true,
    },
    {
        path: '/perfil',
        name: 'Profile',
        component: ProfilePage,
        exact: true,
        private: true,
    },
    {
        path: '/perfil/alterar-senha',
        name: 'ChangePassword',
        component: ChangePasswordPage,
        exact: true,
        private: true,
    },
    {
        path: '/usuarios',
        name: 'Users',
        component: UsersPage,
        exact: true,
        private: true,
    },
    {
        path: '/usuarios/adicionar',
        name: 'UserRegister',
        component: UserRegisterPage,
        exact: true,
        private: true,
    },
    {
        path: '/usuarios/editar/:id',
        name: 'UserEdit',
        component: UserRegisterPage,
        exact: true,
        private: true,
    },
    {
        path: '/usuarios/permissoes',
        name: 'UserPermissions',
        component: UserPermissionsPage,
        exact: true,
        private: true,
    },
    {
        path: '/tabela',
        name: 'Table',
        component: TablePage,
        exact: true,
        private: true,
    },
    {
        path: '/tabela/aprovar',
        name: 'TableAprove',
        component: TableAprove,
        exact: true,
        private: true,
    },
    {
        path: '/aprovacao',
        name: 'Confirmation',
        component: AprovePage,
        exact: true,
        private: true,
    },
];

const Rotas = () => (
    <Switch>
        {routes.map((route, index) => {
            if (route.private) {
                return (
                    <PrivateRoute
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                    />
                );
            }
            return (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            );
        })}
        <Route path="*">
            <Redirect to="/login" />
        </Route>
    </Switch>
);

export default Rotas;

import strings from './strings';
import images from './images';
import colors from './colors';

const R = {
	strings,
	images,
	colors,
};

export default R;

import React from 'react';
import { LoadingContent, Page } from '../../components/Utils/Page';
import TableForm from './TableForm';

class TablePage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Page
				className="tablePg"
				title="Tabela"
				breadcrumbs={[{ name: 'Tabela', active: true }]}>
				<LoadingContent loading={false}>
					<TableForm />
				</LoadingContent>
			</Page>
		);
	}
}

export default TablePage;

import React, { useRef, useEffect } from 'react';
import NotificationSystem from 'react-notification-system';
import { connect } from 'react-redux';
import AuthForm from '../../components/AuthForm';
import PropTypes from '../../lib/utils/propTypes';
import NOTIFICATION_SYSTEM_STYLE from '../../lib/utils/constants';
import R from '../../lib/constants/R';
import { useAuth } from 'store/ducks/auth/sagas';

const AuthPage = ({ loading, notification }) => {
  const notificationSystem = useRef(null);
  const { login } = useAuth();

  const onSubmit = data => {
    login(data);
  };

  return (
    <div id="login-page">
      <AuthForm
        onSubmit={onSubmit}
        // showNotification={showNotification}
        loading={loading}
      />
      {/* <NotificationSystem
        dismissible={false}
        ref={notificationSystem}
        style={NOTIFICATION_SYSTEM_STYLE}
      /> */}
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.api.loading,
  notification: state.notification,
});

AuthPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  notification: PropTypes.shape({
    message: PropTypes.string,
    level: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(AuthPage);

import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import '../../styles/table/table_custom.min.css';
import { ReactTabulator } from 'react-tabulator';
import { ColorRing } from 'react-loader-spinner';
import { CommentaryModal } from '../../components/Utils/CommentaryModal';
import { ShowCommentaryModal } from '../../components/Utils/ShowCommentaryModal';

import { getDataTable, getValorModal } from './TableData';
import { handleSave } from '../../api/appApi/SaveData';
import { is } from 'date-fns/locale';

const REACT_APP_BASE_APP = process.env.REACT_APP_BASE_APP;
const months = [
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Ago',
	'Set',
	'Out',
	'Nov',
	'Dez',
];

const bmonths = [
	'Dez',
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Ago',
	'Set',
	'Out',
	'Nov',
];

const numMonths = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
];

const numMonthsB = [
	'12',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
];

const ReturnMonths = [
	'janeiro',
	'fevereiro',
	'marco',
	'abril',
	'maio',
	'junho',
	'julho',
	'agosto',
	'setembro',
	'outubro',
	'novembro',
	'dezembro',
];

// lets
let date = new Date();
let currentMonth = 0;
let currentYear = date.getFullYear();
let results = [];
let Mresults = [];
let NumResults = [];
const days = [];

const startDate = new Date();
const endDate = new Date();
endDate.setDate(endDate.getDate() + 14);

const uniqueMonths = new Set();

for (let i = 0; i < 62; i++) {
	let nextMonth = (currentMonth + i) % 12;
	let nextYear = currentYear + Math.floor((currentMonth + i) / 12);

	const key = `${nextYear}-${nextMonth + 1}`;

	if (!uniqueMonths.has(key)) {
		results.push({
			month: months[nextMonth],
			bmonth: bmonths[nextMonth],
			year: nextYear.toString().slice(-2),
		});

		Mresults.push({
			month: ReturnMonths[nextMonth],
			year: nextYear.toString().slice(-2),
		});

		NumResults.push({
			month: numMonths[nextMonth],
			bmonth: numMonthsB[nextMonth],
			year: nextYear.toString().padStart(4, '0'),
		});

		uniqueMonths.add(key);
	}
}


for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
	d.setUTCHours(0, 0, 0, 0);
	days.push(
		d
			.toISOString()
			.slice(0, 19)
			.replace('T', ' '),
	);
}

var editCheck = function (cell) {
	var data = cell.getRow().getData();

	return data.grupo === 'Previsão Contas a Pagar';
};

const moneyFormatter = function (cell, formatterParams) {
	const value = parseFloat(cell);
	const decimal = formatterParams.decimal || '.';
	const thousand = formatterParams.thousand || ',';
	const symbol = formatterParams.symbol || '';

	if (value == '' || value == '0' || value == undefined || value == null) {
		return '';
	}

	const stringValue = value.toFixed(2).replace('.', decimal);
	const parts = stringValue.split(decimal);
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand);

	return symbol + parts.join(decimal);
};

const totalFormatter = function (total) {
	const decimal = ','; // Separador decimal brasileiro
	const thousand = '.'; // Separador de milhar brasileiro
	const symbol = 'R$ '; // Símbolo monetário

	if (total === '' || total === '0' || total === undefined || total === null || isNaN(total)) {
		return ''; // Retorna vazio para valores inválidos
	}

	// Formatar o número com duas casas decimais
	const stringValue = total.toFixed(2).replace('.', decimal);
	const parts = stringValue.split(decimal);
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand);

	return symbol + parts.join(decimal); // Retorna o valor formatado com "R$"
};



const handleCellDblClick = (e, cell, props) => {

	const data = cell.getRow().getData();

	if (data.grupo === 'Previsão Contas a Pagar') {

		const queryParams = new URLSearchParams();

		queryParams.append('data', JSON.stringify(data));

		const url = '/tabela/aprovar?' + queryParams.toString();

		// Abre a URL em uma nova guia ou janela
		window.open(url, '_blank');
	}
};

export const getTableData = (setStateFunction) => {
	const month = NumResults[0].month;
	const year = NumResults[0].year;
  
	getDataTable()
	  .then(response => {
		setStateFunction({ datat: response });
	  })
	  .catch(error => {
		console.log(error);
	  });
  
	getValorModal(month, year)
	  .then(response => {
		setStateFunction({ Mdata: response });
	  })
	  .catch(error => {
		console.log(error);
	  });
  
	Promise.all([getDataTable(), getValorModal(month, year)])
	  .then(([dataTableResponse, valorModalResponse]) => {
		const dataTableWithTotalAndAprovado = dataTableResponse.map(dataTableItem => {
		  const valorModalItem = valorModalResponse.find(
			valorModalItem =>
			  valorModalItem.codobra === dataTableItem.codobra &&
			  valorModalItem.codorcam === dataTableItem.codorcam
		  );
		  return {
			...dataTableItem,
			total: valorModalItem ? valorModalItem.total : 0,
			aprovado: valorModalItem ? valorModalItem.aprovacao_pgto : '',
		  };
		});
  

		const somaValores = dataTableWithTotalAndAprovado
		  .filter(item => item.hasOwnProperty('soma_valores'))
		  .reduce((acc, item) => acc + (item.soma_valores || 0), 0);
  
		const removeSomaValores = dataTableWithTotalAndAprovado.filter(item => !item.hasOwnProperty('soma_valores'));
  
		setStateFunction({ soma_valores: somaValores, datat: removeSomaValores });
  
	  })
	  .catch(error => {
		console.log(error);
	  });
  };
  


const uniqueYears = new Set();

results.forEach(result => {
	uniqueYears.add(result.year);
});

const uniqueYearsArray = Array.from(uniqueYears);


var headerMenu = function () {
	var menu = [];
	var columns = this.getColumns();

	for (let column of columns) {

		//create checkbox element using font awesome icons
		let icon = document.createElement("i");
		icon.classList.add("fas");
		icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

		//build label
		let label = document.createElement("span");
		let title = document.createElement("span");

		title.textContent = " " + column.getDefinition().title;

		label.appendChild(icon);
		label.appendChild(title);

		//create menu item
		menu.push({
			label: label,
			action: function (e) {
				//prevent menu closing
				e.stopPropagation();

				//toggle current column visibility
				column.toggle();

				//change menu item icon
				if (column.isVisible()) {
					icon.classList.remove("fa-square");
					icon.classList.add("fa-check-square");
				} else {
					icon.classList.remove("fa-check-square");
					icon.classList.add("fa-square");
				}
			}
		});
	}

	return menu;
};

var style = document.createElement('style');
style.innerHTML = `
		.row-menu-item:hover {
			background-color: #007bff;
			cursor: pointer; 
		}
	`;
document.head.appendChild(style);




const tableStyle = {
	background: '#f5f5f5',
	marginBottom: '5px',
	color: '#000000',
	fontSize: '14px',
	fontWeight: 'bold',
	border: '2px solid #ddd',
	borderRadius: '8px',
	width: '100%',
	tableLayout: 'fixed',
};

const rowStyle = {
	background: '#f5f5f5',
	color: '#000000',
	fontSize: '14px',
	textAlign: 'left',
	border: 'none',
};

class TableForm extends React.Component {
	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
		this.modalRef = React.createRef();
		this.highlightCellWithComment = this.highlightCellWithComment.bind(this);
	}



	state = {
		data1: [],
		datat: [],
		isModalOpen: false,
		isShowCommentary: false,
		selectedCellData: null,
		soma_valores: '',
		commentary: '',
		cursorX: 0,
		cursorY: 0,
	};

	filterData(prevDatat) {
		if (JSON.stringify(this.state.datat) !== JSON.stringify(prevDatat)) {
			const filteredData = this.state.datat.filter(item => !item.hasOwnProperty('soma_valores'));

			if (JSON.stringify(filteredData) !== JSON.stringify(this.state.datat)) {
				this.setState({ datat: filteredData });
			}
		}
	}

	componentDidMount() {
		getTableData(this.setState.bind(this));

		if (this.state.isModalOpen) {
			document.addEventListener('mousedown', this.handleClickOutside);
		}
	}

	componentDidUpdate(_, prevState) {
		if (this.state.datat !== prevState.datat) {

			if (this.state.datat && this.state.datat.length > 0) {
				this.filterData(prevState.datat);
			} else {
				console.log('datat está vazio, nada para filtrar');
			}
		}

		if (this.state.isModalOpen && !prevState.isModalOpen) {
			document.addEventListener('mousedown', this.handleClickOutside);
		} else if (!this.state.isModalOpen && prevState.isModalOpen) {
			document.removeEventListener('mousedown', this.handleClickOutside);
		}
	}


	isOpenModal = () => {
		this.setState({ isModalOpen: true }, () => {
			document.addEventListener('mousedown', this.handleClickOutside);
		});
	};



	closeModal = () => {
		this.setState({ isModalOpen: false }, () => {
			document.removeEventListener('mousedown', this.handleClickOutside);
		});
	};


	handleCellRightClick = (event, cellData, rowIndex, columnIndex, rowValue, Obs) => {
		event.preventDefault(); // Evita que o menu padrão do navegador apareça

		if (!cellData) {
			console.error("❌ Erro: cellData está indefinido ou null!");
			return;
		}

		this.setState(
			{
				selectedCellData: {
					codobra: cellData.codobra || "",
					nmobra: cellData.nmobra || "",
					codorcam: cellData.codorcam || "",
					aunidade: cellData.aunidade || "",
					value: rowValue || "",
					mesano: columnIndex || "",
					obs: Obs || [],
				},
				isModalOpen: true, // Abre o modal ao clicar
			},
			() => {
				console.log("✅ selectedCellData atualizado:", this.state.selectedCellData);
			}
		);
	};




	handleSubmitComment = (obs) => {
		const { selectedCellData } = this.state;

		if (selectedCellData) {
			try {
				if (!selectedCellData.mesano) {
					throw new Error('O campo "mesano" não está definido.');
				}

				// Chamar a função handleSave
				handleSave({
					datat: this.state.datat,
					props: this.props,
					valorCell: selectedCellData.value,
					valorCodObra: selectedCellData.codobra,
					valorCodOrcam: selectedCellData.codorcam,
					dtcolumn: selectedCellData.mesano,
					obs: obs,
				});
			} catch (error) {
				console.error('Erro ao submeter o comentário:', error);
			}
		} else {
			alert('Nenhuma célula foi selecionada.');
		}
	};


	handleClickOutside = (event) => {
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			if (this.state.isModalOpen) {
				this.closeModal();
			}
		}
	};


	showObsModal = (obs) => {
		const modalElement = document.createElement('div');
		modalElement.classList.add('commentary-modal');
		modalElement.innerText = obs;

		// Define a posição do modal com base nas coordenadas do mouse
		modalElement.style.position = 'absolute';
		modalElement.style.left = `${this.state.cursorX + 10}px`;
		modalElement.style.top = `${this.state.cursorY + 10}px`;

		document.body.appendChild(modalElement);

		// Usar função de flecha para garantir que o `this` se refira ao componente
		document.addEventListener('mousemove', (e) => {
			if (!modalElement.contains(e.target)) {
				modalElement.remove();
				document.removeEventListener('mousemove', this);
			}
		});
	};

	highlightCellWithComment = (cell) => {
		const valorCell = cell.getValue();
		const cellElement = cell.getElement();

		const obsArray = cell.getRow().getData().obs || [];
		const mesAno = cell.getColumn().getField();
		const obs = obsArray.find(o => o.mescorrente === mesAno);

		if (obs) {
			cellElement.style.backgroundColor = '';
			cellElement.style.border = '2px solid #007bff';
			cellElement.style.borderRadius = '5px';
		} else {
			cellElement.style.backgroundColor = '';
			cellElement.style.border = '';
		}
	};

	handleCellMouseLeave = () => {
		this.setState({ isShowCommentary: false }, () => {
			// Remover o modal da tela
			const modalElement = document.querySelector('.commentary-modal');
			if (modalElement) {
				modalElement.remove();
			}
		});
	};

	cellMouseOver = (e, cell) => {
		const obsArray = cell.getRow().getData().obs || [];
		const mesAno = cell.getColumn().getField();
		const obs = obsArray.find(o => o.mescorrente === mesAno);

		if (obs) {
			const mouseX = e.clientX;
			const mouseY = e.clientY;

			this.setState({ cursorX: mouseX, cursorY: mouseY, isShowCommentary: true, commentary: obs.obs }, () => {
				this.showObsModal(obs.obs);
			});
		}
	};

	getPreviousMonth() {
		const currentMonth = new Date().getMonth()
		const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1
		return String(previousMonth + 1).padStart(2, '0');
	}

	getPreviousYear() {
		const currentYear = new Date().getFullYear()
		const currentMonth = new Date().getMonth()
		return currentMonth === 0 ? currentYear - 1 : currentYear;
	}


	render() {
		const { isModalOpen, isShowCommentary, cursorX, cursorY, commentary, selectedCellData, soma_valores } = this.state;

		var cellContextMenu = [
			{
				label: "Adicionar Comentário",
				action: (e, cell) => {
					const row = cell.getRow();
					const grupo = row.getData().grupo;

					if (grupo === 'Previsão Contas a Pagar') {
						this.handleCellRightClick(e, cell.getData(), row.getPosition(), cell.getColumn().getField(), cell.getValue(), row.getData().obs);
						this.setState({ isModalOpen: true });
					}
				}
			},
		];


		const SumCtasReceber = this.state.datat.filter(mesano => mesano.hasOwnProperty(`${this.getPreviousMonth()}/${this.getPreviousYear()}`) && mesano.grupo === 'Contas a Receber').map(obj => obj[`${this.getPreviousMonth()}/${this.getPreviousYear()}`])
		const soma = SumCtasReceber.reduce((acc, val) => acc + parseFloat(val), 0);

		const columnData = () => [
			{
				title: 'Descrição',
				field: 'nmobra',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Unidade',
				field: 'aunidade',
				width: 200,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Status',
				field: 'vendido',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Nome do Cliente',
				field: 'nmcliente',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			...results.map((result, index) => ({
				title: results[index].month + '/' + results[index].year,
				field: NumResults[index].month + '/' + NumResults[index].year,
				hozAlign: 'right',
				width: 150,
				contextMenu: cellContextMenu,


				formatter: (cell, formatterParams) => {
					var total = cell.getRow().getData().total;
					var ap = cell.getRow().getData().aprovado;
					var gr = cell.getRow().getData().grupo;

					//pega o comentário da célula
					const obsArray = cell.getRow().getData().obs || [];
					const mesAno = cell.getColumn().getField();
					const obs = obsArray.find(o => o.mescorrente === mesAno);

					if (obs) {
						this.highlightCellWithComment(cell);
						cell.getElement().classList.add('has-obs');
						cell.getElement().setAttribute('data-tooltip', obs.obs);
					}

					const valorCell = cell.getValue();


					let formattedValue = valorCell;

					if (
						(valorCell > 0 && gr === 'Previsão Contas a Pagar') ||
						(valorCell > total && gr === 'Previsão Contas a Pagar')
					) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					} else if (
						(valorCell < 0 && gr === 'Previsão Contas a Pagar') ||
						(valorCell < total && gr === 'Previsão Contas a Pagar')
					) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					}

					if (gr !== 'Previsão Contas a Pagar') {
						if (valorCell >= 0) {
							cell.getElement().style.color = '#108A20';
							formattedValue = moneyFormatter(cell.getValue(), formatterParams);
						} else if (valorCell < 0) {
							cell.getElement().style.color = '#8A1010';
							formattedValue = moneyFormatter(cell.getValue(), formatterParams);
						} else if (valorCell === 'undefined') {
							cell.getElement().style.color = 'blue';
							formattedValue = moneyFormatter(cell.getValue(), formatterParams);
						}
					}
					return formattedValue;
				},
				cellMouseOver: this.cellMouseOver,
				cellMouseLeave: this.handleCellMouseLeave,

				headerMenu: headerMenu,
				formatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				bottomCalc: 'sum',
				bottomCalcParams: { precision: 2 },
				bottomCalcFormatter: function (cell, formatterParams) {
					const valorCell = cell.getValue();
					let formattedValue = valorCell;


					if (parseFloat(valorCell).toFixed(2) === parseFloat(soma).toFixed(2)) {
						cell.getElement().style.color = '#108A20';
						formattedValue = soma_valores ? moneyFormatter(soma_valores, formatterParams) : 0;
					}
					else if (valorCell >= 0) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					} else if (valorCell < 0) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					} else if (valorCell === 'undefined') {
						cell.getElement().style.color = 'blue';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					}
					return formattedValue;
				},

				bottomCalcFormatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				editor: 'input',
				editable: index === 0 ? false : editCheck,
				cellDblClick: (e, cell) =>
					handleCellDblClick(e, cell, this.props),
			})),

			...uniqueYearsArray.map((year) => ({
				title: `Total de 20${year}`,
				field: `Total de 20${year}`,
				hozAlign: 'right',
				width: 150,
				formatter: function (cell) {
					const rowData = cell.getRow().getData();
					const grupo = rowData.grupo;
					let total = 0;
					let totalValue = total;

					// Soma os valores apenas se o grupo for "Previsão Contas a Pagar"
					if (grupo === 'Previsão Contas a Pagar') {
						Object.keys(rowData).forEach((key) => {
							if (key.endsWith(`${year}`)) {
								const value = parseFloat(rowData[key]);
								if (!isNaN(value)) {
									total += value;
								}
							}
						});
					}

					if (total === 0) {
						return ""; // Retorna célula vazia se não houver total
					}

					if (total === 0) {
						return ""; // Retorna "0" se o total for zero
					} else if (total >= 0) {
						cell.getElement().style.color = '#108A20';
						totalValue = totalFormatter(total);
					} else if (total < 0) {
						cell.getElement().style.color = '#8A1010';
						totalValue = totalFormatter(total);
					} else if (total === 'undefined') {
						cell.getElement().style.color = 'blue';
						totalValue = totalFormatter(total);
					}
					return totalValue;

				},
				formatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				bottomCalc: function (values, data) {
					try {
						let total = 0;
						const yearGet = year

						data.forEach((row) => {
							const grupo = row.grupo;

							if (grupo === "Previsão Contas a Pagar") {
								Object.keys(row).forEach((key) => {
									if (key.endsWith(yearGet)) {
										const value = parseFloat(row[key]);
										if (!isNaN(value)) {
											total += value;
										}
									}
								});
							}
						});

						return total;
					} catch (error) {
						console.log('error', error);
					}
				},


				bottomCalcParams: { precision: 2 },
				bottomCalcFormatter: function (cell, formatterParams) {
					const valorCell = cell.getValue();

					let formattedValue = valorCell;
					if (valorCell >= 0) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					} else if (valorCell < 0) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					} else if (valorCell === 'undefined') {
						cell.getElement().style.color = 'blue';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					}
					return formattedValue;
				},

				bottomCalcFormatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
			}))
			, {
				title: `Total`,
				field: `Total`,
				hozAlign: 'right',
				width: 150,
				formatter: function (cell) {
					const rowData = cell.getRow().getData();
					const grupo = rowData.grupo;
					let total = 0;

					// Soma os valores apenas se o grupo for "Previsão Contas a Pagar"
					if (grupo === 'Previsão Contas a Pagar') {
						Object.keys(rowData).forEach((key) => {
							// Verifica se a chave está no formato mm/yyyy
							if (/^\d{2}\/\d{4}$/.test(key)) {
								const value = parseFloat(rowData[key]);
								// Verifica se o valor é um número válido
								if (!isNaN(value)) {
									total += value;
								}
							}
						});
					}

					let totalValue = total;

					if (total === 0) {
						return "";
					} else if (total >= 0) {
						cell.getElement().style.color = '#108A20';
						totalValue = totalFormatter(total);
					} else if (total < 0) {
						cell.getElement().style.color = '#8A1010';
						totalValue = totalFormatter(total);
					} else if (total === 'undefined') {
						cell.getElement().style.color = 'blue';
						totalValue = totalFormatter(total);
					}
					return totalValue;
				},
				formatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				bottomCalc: function (values, data) {
					let total = 0;

					data.forEach((row) => {
						const grupo = row.grupo;

						if (grupo === 'Previsão Contas a Pagar') {
							Object.keys(row).forEach((key) => {
								// Verifica se a key está no formato mm/yyyy
								if (/^\d{2}\/\d{4}$/.test(key)) {
									const value = parseFloat(row[key]);
									if (!isNaN(value)) {
										total += value;
									}
								}
							});
						}
					});

					return total;
				}, // Soma de todos os valores da coluna Total
				bottomCalcParams: { precision: 2 },
				bottomCalcFormatter: function (cell, formatterParams) {
					const valorCell = cell.getValue();

					let formattedValue = valorCell;
					if (valorCell >= 0) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					} else if (valorCell < 0) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					} else if (valorCell === 'undefined') {
						cell.getElement().style.color = 'blue';
						formattedValue = moneyFormatter(cell.getValue(), formatterParams);
					}
					return formattedValue;
				},
				bottomCalcFormatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
			}

		];


		const optionsTable = {
			height: '110%',
			contextMenu: cellContextMenu,
			layout: 'fitDataTable',
			autoCOlumns: true,
			movableColumns: true,
			frozenColumns: false,
			stickyHeader: false,
			frozenColumns: 4,
			groupBy: 'grupo',
			reactiveData: true,
			groupStartOpen: false,
			columnCalcs: 'group',
			groupClosedShowCalcs: true,
			virtualDom: true,
			virtualDomBuffer: 300,
		};
		return (
			<div className="parent">
				<div className="div1">
					{/* exibe modal para criar um comentário */}
					{isModalOpen && (
						<div
							style={{
								position: 'absolute',
								borderRadius: '4px',
								zIndex: 1000,
								boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
							}}
							ref={this.modalRef}
						>
							<CommentaryModal closeModal={this.closeModal} submitComment={this.handleSubmitComment} commentary={selectedCellData.obs[0]?.obs} />
						</div>
					)}

					{/* exibe o modal com comentário */}
					{isShowCommentary && (
						<ShowCommentaryModal
							comment={commentary}
							position={{
								left: cursorX - 310 > 0 ? cursorX - 310 : 10,
								top: cursorY + 10 < window.innerHeight - 200 ? cursorY + 10 : window.innerHeight - 200,
							}}
						/>
					)}

					{/* tabela Dados */}
					{this.state.datat.length > 0 ? (
						<ReactTabulator

							style={tableStyle}
							rowStyle={rowStyle}
							columns={columnData()}
							data={this.state.datat}
							options={optionsTable}
							ref={this.tableRef}
							events={{
								cellEdited: function (cell) {
									let rowData = cell.getRow().getData();
									let columnField = cell.getColumn().getField();
									let newValue = cell.getValue();

									handleSave({
										datat: '',
										props: '',
										valorCell: newValue,
										valorCodObra: rowData.codobra,
										valorCodOrcam: rowData.codorcam,
										dtcolumn: columnField,
										obs: rowData.obs
									});
								}

							}}
						/>
					) : (
						<div className='loadingDiv'>
							<ColorRing
								visible={true}
								height="80"
								width="80"
								ariaLabel="color-ring-loading"
								wrapperStyle={{}}
								wrapperClass="color-ring-wrapper"
								colors={['#004992', '#004992', '#004992', '#004992', '#004992']}
							/>
						</div>
					)}

				</div>
			</div>
		);
	}
}

export default withRouter(TableForm);

import React, { useState } from 'react';
import {
	MdCalendarToday,
	MdExitToApp,
	MdHelp,
	MdPersonPin,
} from 'react-icons/md';
import { RiMenuUnfoldLine } from 'react-icons/ri';
import {
	Button,
	ListGroup,
	ListGroupItem,
	Nav,
	Navbar,
	NavItem,
	NavLink,
	Popover,
	PopoverBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import bn from '../../../lib/utils/bemnames';
import {
	authActions,
	enterpriseActions,
	genericsActions,
} from '../../../store/actions';
import { navigate } from '../../../lib/utils/navigation';
import Avatar from '../Avatar';
import { UserCard } from '../Card';
import PropTypes from '../../../lib/utils/propTypes';
import R from '../../../lib/constants/R';
import SourceLink from '../SourceLink';
import { useUser } from 'Context/UserContext';
import { useAuth } from 'store/ducks/auth/sagas';

const bem = bn.create('header');

const Header = (props) => {
	const [isOpenNotificationPopover, setIsOpenNotificationPopover] = useState(false);
	const [isNotificationConfirmed, setIsNotificationConfirmed] = useState(false);
	const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);
	const [isOpenSide, setIsOpenSide] = useState(true);
	const { usuario, SetUser } = useUser();
	const { logout } = useAuth();

	const toggleNotificationPopover = () => {
		setIsOpenNotificationPopover(!isOpenNotificationPopover);

		if (!isNotificationConfirmed) {
			setIsNotificationConfirmed(true);
		}
	};

	const toggleUserCardPopover = () => {
		setIsOpenUserCardPopover(!isOpenUserCardPopover);
	};

	const handleSidebarControlButton = (event) => {
		event.preventDefault();
		event.stopPropagation();

		document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
		document.querySelector('.cr-header').classList.toggle('open');

		if (document.querySelector('.cr-sidebar').classList.contains('cr-sidebar--open')) {
			setIsOpenSide(false);
		} else {
			setIsOpenSide(true);
		}

		const { onSidebar } = props;
		onSidebar(isOpenSide);
	};

	const {
		companies,
		select,
		onChangeCompany,
		onLogoutUser,
		isOpen,
	} = props;

	const diasDaSemana = [
		'Domingo',
		'Segunda-feira',
		'Terça-feira',
		'Quarta-feira',
		'Quinta-feira',
		'Sexta-feira',
		'Sábado',
	];
	const meses = [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	];

	const dataAtual = new Date();
	const diaDaSemana = diasDaSemana[dataAtual.getDay()];
	const diaDoMes = dataAtual.getDate();
	const mes = meses[dataAtual.getMonth()];
	const ano = dataAtual.getFullYear();

	const day = `${diaDaSemana}, ${diaDoMes} de ${mes} de ${ano}`;

	return (
		<Navbar light expand className={bem.b('open')}>
			{isOpen && (
				<Nav navbar className="mr-2">
					<Button
						outline
						onClick={handleSidebarControlButton}
						className={bem.e('buttonHam')}>
						<RiMenuUnfoldLine size={20} />
					</Button>
				</Nav>
			)}
			{!isOpen && (
				<SourceLink
					className="fixed-bottom m-3"
					link={'https://www.modelovencedor.com.br'}
					style={{ width: '198.32px' }}>
					<img src={R.images.mv} height="40" alt="" />
				</SourceLink>
			)}
			<Nav style={{ paddingTop: '5px' }} className={bem.e('date')}>
				<MdCalendarToday size={20} color="#fff" />
				<span style={{ paddingLeft: '10px', fontWeight: 'bolder' }}>
					{day}
				</span>
			</Nav>

			<Nav navbar className={bem.e('nav-right')}>
				<NavItem>
					<NavLink id="Popover2">
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								fontSize: '20px',
								paddingRight: '10px',
								fontWeight: '500',
								color: '#0A264B',
								fontFamily: 'Raleway, sans-serif',
							}}>
							Olá,
							<span
								style={{
									fontWeight: 'bolder',
									marginLeft: '5px',
								}}>
								{usuario ? usuario.nome : 'Usuário'}
							</span>
						</div>
						<Avatar
							style={{ color: '#0A264B' }}
							onClick={toggleUserCardPopover}
							className="can-click mr-3"
							src={
								usuario && usuario.img
									? `${process.env.REACT_APP_BASE_APP}${usuario.img}`
									: R.images.logo_teste
							}
						/>
					</NavLink>
					<Popover
						placement="bottom-end"
						isOpen={isOpenUserCardPopover}
						toggle={toggleUserCardPopover}
						target="Popover2"
						className="p-0 border-0"
						style={{ minWidth: 250 }}>
						{usuario && (
							<PopoverBody className="p-0 border-light">
								<UserCard
									title={usuario.nome}
									subtitle={usuario.email}
									className="border-light"
									avatar={
										usuario.img
											? `${process.env.REACT_APP_BASE_APP}${usuario.img}`
											: R.images.logo_teste
									}>
									<ListGroup flush>
										<ListGroupItem
											tag="button"
											action
											className="border-light"
											onClick={() => navigate('/perfil')}>
											<MdPersonPin /> Perfil
										</ListGroupItem>
										<ListGroupItem
											tag="button"
											action
											className="border-light"
											onClick={() => navigate('/ajuda')}>
											<MdHelp /> Ajuda
										</ListGroupItem>
										<ListGroupItem
											tag="button"
											action
											className="border-light"
											onClick={() => logout()}>
											<MdExitToApp /> Sair
										</ListGroupItem>
									</ListGroup>
								</UserCard>
							</PopoverBody>
						)}
					</Popover>
				</NavItem>
			</Nav>
		</Navbar>
	);
};

const mapStateToProps = (state) => {
	return {
		companies: state.enterprise.companies,
		select: state.enterprise.selecCompany,
		isOpen: state.generics.isOpen,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLogoutUser: () => dispatch(authActions.logoutUser()),
		onChangeCompany: (value) => dispatch(enterpriseActions.changeCompany(value)),
		onSidebar: (data) => dispatch(genericsActions.sideBar(data)),
	};
};

Header.propTypes = {
	onChangeCompany: PropTypes.func.isRequired,
	onLogoutUser: PropTypes.func.isRequired,
	companies: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.arrayOf(PropTypes.object),
	]).isRequired,
	select: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
	//   usuario: PropTypes.oneOfType([
	//     PropTypes.bool,
	//     PropTypes.shape({
	//       nome: PropTypes.string,
	//       email: PropTypes.string,
	//     }),
	//   ]).isRequired,
};

Header.defaultProps = {
	select: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Button, NavLink as BSNavLink } from 'reactstrap';
import bn from '../../../lib/utils/bemnames';
import { navItems } from '../../../routes/menu';
import R from '../../../lib/constants/R';
import SidebarPage from './SidebarPages';
import SourceLink from '../SourceLink';
import PropTypes from '../../../lib/utils/propTypes';
import { MdMenuOpen, MdRefresh } from 'react-icons/md';
import { genericsActions } from '../../../store/actions';
import api from '../../../api/appApi/api';
import { useUser } from 'Context/UserContext';
import { connect } from 'react-redux';

const sidebarBackground = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

const Sidebar = ({ modules, permissionsMenu, onSidebar }) => {
    const [isOpenSide, setIsOpenSide] = useState(true);
    const [isUpdating, setIsUpdating] = useState(0);
    const [progress, setProgress] = useState(0);

    const handleSidebarControlButton = event => {
        event.preventDefault();
        event.stopPropagation();
        const sidebar = document.querySelector('.cr-sidebar');
        const header = document.querySelector('.cr-header');
        sidebar.classList.toggle('cr-sidebar--open');
        header.classList.toggle('open');

        setIsOpenSide(!sidebar.classList.contains('cr-sidebar--open'));
        onSidebar(!sidebar.classList.contains('cr-sidebar--open'));
    };

    const handleClick = name => () => {
        setIsOpenSide(prevState => ({
            ...prevState,
            [`isOpen${name}`]: !prevState[`isOpen${name}`],
        }));
    };

    const modulePermission = (modules) => {
        if (modules.length > 0) {
            return modules.map((item, index) => (
                <SidebarPage
                    key={item.nome + index}
                    isOpen={isOpenSide[`isOpen${item.nome}`]}
                    namePages={item.nome}
                    click={name => handleClick(name)}
                    pageContents={item.submodulos}
                    IconPage={item.icone}
                />
            ));
        } else {
            return null;
        }
    };

    const updateTableData = async () => {

        setIsUpdating(1); 
        setProgress(0);  

        try {
            const response = await api.get('/executar-python');
            console.log('response', response.status);

            if (response.status === 202 || response.status === 200) {
                pollProgress();
            } else {
                setIsUpdating(3);
            }
        } catch (error) {
            console.error('Erro ao executar o script:', error);
            handleScriptError();
        }
    };

    const pollProgress = async () => {
        const interval = setInterval(async () => {
            try {
                const progressResponse = await api.get('/get-progress');
                console.log('progressResponse', progressResponse);

                if (progressResponse.status === 200) {
                    const { details } = progressResponse.data;
                    setProgress(details); 
                    if (details.includes('100')) { 
                        clearInterval(interval);
                        setIsUpdating(0);
                    }
                }
            } catch (error) {
                console.error('Erro ao obter progresso:', error);
                clearInterval(interval);
                setIsUpdating(0);
            }
        }, 1000);
    };

    const handleScriptError = () => {
        setIsUpdating(3); 
        setTimeout(() => {
            setIsUpdating(0);
        }, 10000);
    };

    const { usuario } = useUser();

    return (
        <aside className={bem.b()} data-image={R.images.sidebar}>
            <div className={bem.e('background')} style={sidebarBackground} />

            <div className={bem.e('content')}>
                <div style={{ display: 'flex', justifyContent: 'space-around', padding: '0 5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SourceLink style={{ backgroundColor: 'white', padding: 5, borderRadius: '0 0 10px 10px' }}>
                            <img src={R.images.logo_oficial} height="40" alt="" />
                        </SourceLink>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button outline onClick={handleSidebarControlButton} className={bem.e('menu')}>
                            <MdMenuOpen size={20} />
                        </Button>
                    </div>
                </div>

                <Nav vertical>
                    {navItems.map(({ to, name, exact, Icon }, index) => (
                        <NavItem key={name} className="custom-nav-item">
                            <BSNavLink
                                id={`navItem-${name}-${index}`}
                                tag={NavLink}
                                to={to}
                                activeClassName="active"
                                exact={exact}
                            >
                                <Icon className={bem.e('nav-item-icon')} />
                                <span className="nav-name">{name}</span>
                            </BSNavLink>
                        </NavItem>
                    ))}
                    {usuario?.modulo ? modulePermission(usuario.modulo) : null}

                    <NavItem key="Atualização" className="custom-nav-item">
                        <Button onClick={updateTableData} className="updateButton">
                            <MdRefresh style={{ marginRight: '.5rem', fontSize: '1.9rem' }} size={20} />
                            <span className="nav-name">
                                {isUpdating === 1
                                    ? `${progress}`
                                    : isUpdating === 3
                                        ? `${progress}%`
                                        : 'Atualizar'}
                            </span>
                        </Button>
                    </NavItem>
                </Nav>
            </div>
        </aside>
    );
};

const mapStateToProps = state => ({
    modules: state.enterprise.modulesCompany,
    permissionsMenu: state.enterprise.permissionsMenu,
});

const mapDispatchToProps = dispatch => ({
    onSidebar: data => dispatch(genericsActions.sideBar(data)),
});

Sidebar.propTypes = {
    permissionsMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
    modules: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSidebar: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

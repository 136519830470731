import axios from 'axios';

const months = [
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Aug',
	'Set',
	'Out',
	'Nov',
	'Dez',
];

const bmonths = [
	'Dez',
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Aug',
	'Set',
	'Out',
	'Nov',
];

const numMonths = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
];

const numMonthsB = [
	'12',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
];

const ReturnMonths = [
	'janeiro',
	'fevereiro',
	'marco',
	'abril',
	'maio',
	'junho',
	'julho',
	'agosto',
	'setembro',
	'outubro',
	'novembro',
	'dezembro',
];

let date = new Date();
let currentMonth = date.getMonth();
let currentYear = date.getFullYear();
let results = [];
let Mresults = [];
let NumResults = [];

const REACT_APP_BASE_APP = process.env.REACT_APP_BASE_APP;
const startDate = new Date();
const endDate = new Date();
endDate.setDate(endDate.getDate() + 14);

for (let i = 0; i < 13; i++) {
	let nextMonth = (currentMonth + i) % 12;
	let nextYear = currentYear + Math.floor((currentMonth + i) / 12);
	results.push({
		month: months[nextMonth],
		bmonth: bmonths[nextMonth],
		year: nextYear.toString().slice(-2),
	});
	Mresults.push({
		month: ReturnMonths[nextMonth],
		year: nextYear.toString().slice(-2),
	});
	NumResults.push({
		month: numMonths[nextMonth],

		bmonth: numMonthsB[nextMonth],
		year: nextYear.toString(),
	});
}

const days = [];
for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
	d.setUTCHours(0, 0, 0, 0); // define a hora como 00:00:00 no fuso horário UTC
	days.push(
		d
			.toISOString()
			.slice(0, 19)
			.replace('T', ' '),
	);
}

export const getCaixaData = async () => {
	try {
		let url = `${REACT_APP_BASE_APP}caixatable`;
		const response = await fetch(url);
		return await response.json();
	} catch (error) {
		console.log('error: ', error);
	}
};

export const getDataTable = async () => {
	try {
		let url = `${REACT_APP_BASE_APP}datatable`;
		const response = await fetch(url);
		return await response.json();
	} catch (error) {
		console.log('error: ', error);
	}
};

export const getDataModal = async (codobra, codorcam, month, year) => {
	try {
		let url = `
			${REACT_APP_BASE_APP}ctaspagar
			?codobra=${codobra}
			&codorcam=${codorcam}
			&dataprev=${month}/${year}`;
		const response = await fetch(url);
		return await response.json();
	} catch (error) {
		console.log('error: ', error);
	}
};
export const getValorModal = async (month, year) => {
	try {
		let url = `${REACT_APP_BASE_APP}compararvalor?dataprev=${month}/${year}`;
		const response = await fetch(url);
		return await response.json();
	} catch (error) {
		console.log('error: ', error);
	}
};
export const getAproveTable = async (month, year) => {
	try {
		let url = `${REACT_APP_BASE_APP}aprovetable?dataprev=${month}/${year}`;
		const response = await fetch(url);
		return await response.json();
	} catch (error) {
		console.log('error: ', error);
	}
};

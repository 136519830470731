import React from 'react';
import { Form, Row } from 'reactstrap';
import { MdInfoOutline } from 'react-icons/md';
import PropTypes from '../../../lib/utils/propTypes';
import Card from '../../Utils/Card/FormCard';
import { ButtonsCancelSubmit, Typography } from '../../Utils';
import { ContentItem, NavItem, TabContent, TabNav } from '../../Utils/Tabs';
import CompanyPermissions from './Company';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const FormUser = ({
	btnLabelSubmit,
	btnLabelCancel,
	handleNavigation,
	permissions,
	onSubmit,
	...restProps
}) => {

	const [listaPermissoes, setListaPermissoes] = React.useState([]);

	const copy = permissions;

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	console.log('queryParams', queryParams);
	const userId = Number(queryParams.get('usuario'));


	const changeValue = (submoduloId, moduloId, permittion, empresaId) => {

		const index = listaPermissoes.findIndex(item =>
			item.usuario_id === userId &&
			item.modulo_id === moduloId &&
			item.submodulo_id === submoduloId &&
			item.empresa_id === empresaId
		);

		if (index === -1) {

			listaPermissoes.push({
				usuario_id: userId,
				modulo_id: moduloId,
				submodulo_id: submoduloId,
				empresa_id: empresaId,
				ativo: permittion,
			});
		} else {

			listaPermissoes[index] = {
				...listaPermissoes[index],
				ativo: permittion
			};
		}

	};


	const renderCompanyPermissions = (modulo, empresaId) => {

		if (modulo.length > 0) {
			return modulo.map((modulos, indexSubmodulo) => (

				<CompanyPermissions
					key={modulos.submodulos.id}
					company={modulos}
					indexCompany={indexSubmodulo}
					changeValue={(submoduloId, moduloId, permittion) => changeValue(submoduloId, moduloId, permittion, empresaId)}
				/>
			));
		}

	};

	const submit = () => {
		onSubmit(listaPermissoes);
	};

	return (
		<Card title="Configurar Permissões" {...restProps}>
			<Row className="ml-2">
				<MdInfoOutline size={15} className="mr-1 mb-3" />
				<Typography type="small" className="mb-3">
					Todos os botões desabilitados são permissões referentes ao grupo.
				</Typography>
			</Row>
			<Form>
				{copy.permission && (
					<div>
						<TabNav>
							{copy.permission[0].empresa.map((empresa, empresaIndex) => (
								<NavItem
									key={empresa.id}
									number={empresaIndex + 1}
									title={empresa.razao_social}
								/>
							))}
						</TabNav>
						<TabContent>
							{copy.permission[0].empresa.map((empresa, empresaIndex) => (
								<ContentItem
									number={empresaIndex + 1}
									key={empresa.id}
									className="mt-5 ml-2"
								>
									{copy.permission[1].permission.map((modulo, moduloIndex) => (
										<div key={modulo.id}>
											{renderCompanyPermissions([modulo], empresa.id)}
										</div>
									))}
								</ContentItem>
							))}
						</TabContent>
					</div>
				)}
				<ButtonsCancelSubmit
					labelSubmit={btnLabelSubmit}
					onSubmit={submit}
					labelCancel={btnLabelCancel}
					onCancel={handleNavigation}
				/>
			</Form>
		</Card>
	);
};

FormUser.propTypes = {
	btnLabelSubmit: PropTypes.string,
	btnLabelCancel: PropTypes.string,
	handleNavigation: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	permissions: PropTypes.arrayOf(PropTypes.object)
};

FormUser.defaultProps = {
	btnLabelSubmit: 'Salvar',
	btnLabelCancel: 'Cancelar',
};

export default FormUser;

export async function handleSave({
    datat,
    props,
    valorCell,
    valorCodObra,
    valorCodOrcam,
    dtcolumn,
    obs,
  }) {
    console.log('dtcolumn', dtcolumn);
    try {
      // Converte dtcolumn no formato necessário
      const parts = dtcolumn.split('/');
      const month = parseInt(parts[0], 10); // Mês
      const year = parseInt(parts[1], 10); // Ano
  
      const convertedDate = new Date(year, month - 1, 1);
      const dtData = convertedDate.toISOString().slice(0, 10);
  
      // Dados para salvar
      const saveData = {
        codobra: valorCodObra,
        codorcam: valorCodOrcam,
        valor: valorCell,
        dataprev: dtData,
        obs: obs ? obs : '',
      };
  
      // Configuração da requisição
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(saveData),
      };
  
      // Envia a requisição para a API
      const response = await fetch(`${process.env.REACT_APP_BASE_APP}datatable`, requestOptions);
  
      if (response.status !== 200 && response.status !== 201) {
        throw new Error('Erro ao salvar as informações na API');
      }
  
      console.log('Dados salvos com sucesso:', saveData);
  
    } catch (error) {
      console.error('Erro ao salvar os dados:', error);
    }
  }
  
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import PropTypes from '../../../lib/utils/propTypes';
import Card from '../../Utils/Card/FormCard';
import { Checkbox, InputLabel, SelectLabel } from '../../Utils';
import api from 'api/authApi/api';

import { useHistory } from 'react-router-dom';

const FormUser = ({
    userLabel,
    userInputProps,
    emailLabel,
    emailInputProps,
    firstNameLabel,
    firstNameInputProps,
    lastNameLabel,
    lastNameInputProps,
    companyLabel,
    companyInputProps,
    interLoginLabel,
    interLoginInputProps,
    extraIdLabel,
    extraIdInputProps,
    groupLabel,
    groupInputProps,
    activeLabel,
    activeInputProps,
    typeLabel,
    typeInputProps,
    passwordLabel,
    passwordInputProps,
    confirmLabel,
    confirmInputProps,
    btnLabelSubmit,
    btnLabelCancel,
    handleNavigation,
    onSubmit,
    user,
    edit,
    groups,
    representatives,
    showNotification,
    ...restProps
}) => {
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: user,
    });

    const history = useHistory();

    const onSubmitForm = async data => {
        try {
            if (data.senha === data.confirmar_senha) {
                const dados = {
                    login: data.login,
                    email: data.email,
                    nome: data.nome,
                    ativo: data.ativo,
                    senha: data.senha,
                    sistema: data.sistema,
                };

                if (user.id) {
                    await api.put(`/usuario/${user.id}`, dados);

                    history.push('/usuarios');
                } else {
                    const response = await api.post('/usuario', data);
                    const userId = response.data.id;

                    history.push(`/usuarios/permissoes?usuario=${userId}`);
                }
            } else {
                showNotification('As senhas não conferem.', 'error');
            }
        } catch (error) {
            console.error('Erro ao salvar usuário:', error.response ? error.response.data : error.message);
        }
    };


    return (
        <Card title="Novo usuário">
            <form onSubmit={handleSubmit(onSubmitForm)} {...restProps}>
                <Row>
                    <Col xl={4} lg={4} md={4}>
                        <InputLabel
                            label={userLabel}
                            {...userInputProps}
                            innerRef={register}
                        />
                    </Col>
                    <Col xl={4} lg={4} md={4}>
                        <InputLabel
                            label={emailLabel}
                            {...emailInputProps}
                            innerRef={register}
                        />
                    </Col>
                    <Col xl={4} lg={4} md={4}>
                        <InputLabel
                            label={firstNameLabel}
                            {...firstNameInputProps}
                            innerRef={register}
                        />
                    </Col>
                </Row>
                {/* <Row>
                   
                    <Col xl={6} lg={12} md={12}>
                        <SelectLabel
                            label={companyLabel}
                            {...companyInputProps}
                            options={companies}
                            value={selectedOption}
                            onChange={handleMultiChange}
                        />
                    </Col>
                </Row> */}
                <Row>
                    <Col xl={4} lg={12} md={12}>
                        <Checkbox
                            {...activeInputProps}
                            innerRef={register}
                            label={activeLabel}
                        />
                    </Col>
                    <Col xl={4} lg={12} md={12}>
                        <Checkbox
                            {...typeInputProps}
                            innerRef={register}
                            label={typeLabel}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={12} md={12}>
                        <InputLabel
                            label={passwordLabel}
                            required={!edit}
                            {...passwordInputProps}
                            innerRef={register}
                        />
                    </Col>
                    <Col xl={6} lg={12} md={12}>
                        <InputLabel
                            required={!edit}
                            label={confirmLabel}
                            {...confirmInputProps}
                            innerRef={register}
                        />
                    </Col>
                </Row>
                <Button
                    color="danger"
                    outline
                    className="float-left col-md-2 mt-3"
                    onClick={() => handleNavigation()}>
                    {btnLabelCancel}
                </Button>
                <Button
                    color="success"
                    outline
                    type="submit"
                    className="float-right col-md-2 mt-3">
                    {btnLabelSubmit}
                </Button>
            </form>
        </Card>
    );
};

FormUser.propTypes = {
    userLabel: PropTypes.string,
    userInputProps: PropTypes.shape({}),
    emailLabel: PropTypes.string,
    emailInputProps: PropTypes.shape({}),
    firstNameLabel: PropTypes.string,
    firstNameInputProps: PropTypes.shape({}),
    lastNameLabel: PropTypes.string,
    lastNameInputProps: PropTypes.shape({}),
    companyLabel: PropTypes.string,
    companyInputProps: PropTypes.shape({}),
    interLoginLabel: PropTypes.string,
    interLoginInputProps: PropTypes.shape({}),
    extraIdLabel: PropTypes.string,
    extraIdInputProps: PropTypes.shape({}),
    groupLabel: PropTypes.string,
    groupInputProps: PropTypes.shape({}),
    typeLabel: PropTypes.string,
    typeInputProps: PropTypes.shape({}),
    activeLabel: PropTypes.string,
    activeInputProps: PropTypes.shape({}),
    passwordLabel: PropTypes.string,
    passwordInputProps: PropTypes.shape({}),
    confirmLabel: PropTypes.string,
    confirmInputProps: PropTypes.shape({}),
    btnLabelSubmit: PropTypes.string,
    btnLabelCancel: PropTypes.string,
    handleNavigation: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    edit: PropTypes.bool,
    companiesList: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({ group: PropTypes.object }),
    ])
};

FormUser.defaultProps = {
    userLabel: 'Usuário',
    userInputProps: {
        name: 'login',
        id: 'login',
        placeholder: 'usuário',
        required: true,
    },
    emailLabel: 'Email',
    emailInputProps: {
        name: 'email',
        id: 'email',
        type: 'email',
        placeholder: 'email@email.com',
        required: true,
    },
    firstNameLabel: 'Nome',
    firstNameInputProps: {
        name: 'nome',
        id: 'nome',
        placeholder: 'nome',
        required: true,
    },
    companyLabel: 'Empresa',
    companyInputProps: {
        name: 'empresaId',
        id: 'empresaId',
        placeholder: 'selecione a empresa',
        required: true,
        isMulti: 'false',
    },
    extraIdLabel: 'Representante ERP',
    extraIdInputProps: {
        type: 'number',
        name: 'extra_id',
        id: 'extra_id',
        placeholder: 'selecione um representante',
    },
    groupLabel: 'Grupo de permissões',
    groupInputProps: {
        name: 'group',
        id: 'group',
        placeholder: 'selecione o grupo de permissões',
    },
    typeLabel: 'Administrador',
    typeInputProps: {
        name: 'sistema',
        id: 'sistema',
    },
    activeLabel: 'Ativo',
    activeInputProps: {
        name: 'ativo',
        id: 'ativo',
    },
    passwordLabel: 'Senha',
    passwordInputProps: {
        name: 'senha',
        id: 'senha',
        type: 'password',
        placeholder: 'senha',
    },
    confirmLabel: 'Confirmar Senha',
    confirmInputProps: {
        name: 'confirmar_senha',
        id: 'confirmar_senha',
        type: 'password',
        placeholder: 'confirmar senha',
    },
    btnLabelSubmit: 'Salvar',
    btnLabelCancel: 'Cancelar',
};

export default FormUser;

import React from 'react';

export const ShowCommentaryModal = ({ comment, position }) => {

    return (
        <div style={{ ...styles.modalContainer, top: position.top, left: position.left }}>
            <div style={styles.header}>
                <h3 style={styles.title}>Comentário</h3>
            </div>
            <p style={styles.comment}>{comment}</p>
        </div>
    );
};

const styles = {
    modalContainer: {
        position: 'absolute',
        background: '#fff',
        padding: '20px',
        borderRadius: '12px',
        width: '300px',
        boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
        textAlign: 'left',
        zIndex: 1000,
        animation: 'fadeIn 0.3s ease-out', // Animação de fade-in
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333',
        margin: 0,
    },
    closeButton: {
        fontSize: '20px',
        border: 'none',
        background: 'none',
        color: '#aaa',
        cursor: 'pointer',
        padding: '5px',
        transition: 'color 0.2s',
    },
    closeButtonHover: {
        color: '#e74c3c', // Cor vermelha ao passar o mouse
    },
    comment: {
        fontSize: '14px',
        color: '#555',
        lineHeight: '1.6',
        marginBottom: '15px',
    },
};

// Adicionar animação de fade-in
const globalStyles = `
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
`;

document.head.insertAdjacentHTML('beforeend', `<style>${globalStyles}</style>`);


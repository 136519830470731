import React from 'react';
import ReactDOM from 'react-dom/client'; // Atualize esta importação
import { Provider } from 'react-redux';

import configureStore from './store/index';
import App from './App';
import Stepper from 'bs-stepper';

export const store = configureStore();
export default store;

const root = ReactDOM.createRoot(document.getElementById('root')); // Usando createRoot
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

/* eslint-disable global-require */
const images = {
	logo_teste: require('../../assets/img/users/user-icon.png'),
	logo_oficial: require('../../assets/img/logo/logo_color_2.png'),
	logo_white: require('../../assets/img/logo/logo_white.png'),
	banner_login: require('../../assets/img/banners/banner_login.png'),
	banner_frase: require('../../assets/img/banners/banner_frase.png'),
	welcome: require('../../assets/img/banners/welcome.png'),
	mv: require('../../assets/img/logo/Powerby_MV.png'),
};

export default images;
